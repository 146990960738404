import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";  
import { Box, Button, Input, Text, useToast } from "@chakra-ui/react";

const LocationApi = ({ onLocationSelect, closeDrawer }) => {
  const [locationData, setLocationData] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(
    JSON.parse(localStorage.getItem("selectedLocation")) || null
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const API_KEY = "pk.44751ab97d1c02b694611cb9af83689a";
  const { isAuth } = useSelector((store) => store.auth);  
  const toast = useToast();

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `https://us1.locationiq.com/v1/search.php?key=${API_KEY}&q=${searchQuery}&format=json`
      );
      if (!response.ok) {
        console.error("Response status:", response.status);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setLocationData(data);
      setShowSuggestions(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    setShowSuggestions(false);
  };

  const handleSelectLocation = (selected) => {
    const displayNameParts = selected.display_name.split(",");
    const shortenedDisplayName = displayNameParts.slice(0, 1).join(",");
    setSelectedLocation({ ...selected, display_name: shortenedDisplayName });
    setSearchQuery(shortenedDisplayName);
    setShowSuggestions(false);
    onLocationSelect({ ...selected, display_name: shortenedDisplayName });
  };

  const handleSubmit = () => {
    if (selectedLocation) {
      onLocationSelect(selectedLocation);
      closeDrawer();
      toast({
        position: "top",
        title: "Location added! 🎉 Check out our product now. 🛒",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } else {
      console.log("No location selected");
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="20px"
      backgroundColor="#f9f9f9"
      borderRadius="8px"
      boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
    >
      {!isAuth ? (
        <Text textAlign="center" fontSize="18px" color="#ff3269">
          Login Required!!.
        </Text>
      ) : (
        <>
          <Box width="100%" maxWidth="600px" marginBottom="20px">
            <Input
              type="text"
              value={searchQuery}
              onChange={handleInputChange}
              placeholder="Search for a location..."
              size="lg"
              marginBottom="10px"
            />
            <Button
              onClick={handleSearch}
              backgroundColor="#ff3269"
              color="white"
              size="md"
              _hover={{ backgroundColor: "#e62e5b" }}
            >
              Search
            </Button>
          </Box>
          {showSuggestions && locationData && (
            <Box
              position="relative"
              width="100%"
              maxWidth="600px"
              border="1px solid #ccc"
              backgroundColor="white"
              borderRadius="4px"
              boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
              maxHeight="300px"
              overflowY="auto"
            >
              {locationData.map((location) => (
                <Text
                  key={location.place_id}
                  onClick={() => handleSelectLocation(location)}
                  padding="10px"
                  cursor="pointer"
                  _hover={{ backgroundColor: "#f1f1f1" }}
                >
                  {location.display_name}
                </Text>
              ))}
            </Box>
          )}
          {selectedLocation && (
            <Box marginTop="20px" textAlign="center">
              <Text fontSize="20px" marginBottom="10px">
                Selected Location on Map:
              </Text>
              <iframe
                title="Selected Location Map"
                width="600"
                height="300"
                loading="lazy"
                allowFullScreen
                src={`https://maps.google.com/maps?q=${selectedLocation.lat},${selectedLocation.lon}&z=15&output=embed`}
                style={{
                  border: "none",
                  borderRadius: "4px",
                  margin: "0 auto",
                }}
              ></iframe>
              <Button
                onClick={handleSubmit}
                backgroundColor="#ff3269"
                color="white"
                size="md"
                marginTop="16px"
                _hover={{ backgroundColor: "#e62e5b" }}
              >
                Submit
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default LocationApi;